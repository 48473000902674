import React, { useRef, useEffect } from "react"
import { Loader } from "@googlemaps/js-api-loader"
import iconLogo from "../images/icons-black/icon-48x48.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLocationArrow, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"

const mapStyles = {
  position: "relative",
  width: "100%",
  height: "400px",
  boder: `1px solid pink`,
}
const position = {
  lat: 33.9812,
  lng: -84.1542,
}
const MapSection = () => {

  const googleMapRef = useRef()

    const loader = new Loader({
    apiKey: "AIzaSyBqv2OvyV4KeSYNNK2-zl366NIkh4yUtH8",
    version: "weekly",
    libraries: ["places"],
    language: "en",
    region: "US",
  })

  const mapOptions = {
    center: {
      lat: 33.9812,
      lng: -84.1542,
    },
    zoom: 12,
  }

    // Promise
  useEffect(() => {
    loader
      .load()
      .then(() => {
        let map = new window.google.maps.Map(googleMapRef.current, mapOptions)
        let marker = new window.google.maps.Marker({
          position: { lat: 33.9812, lng: -84.1542, },
          map: map,
          // title: "Moussa Drame Tennis",
          icon: iconLogo,
        })
        marker.setMap(map)
        //   new google.maps.Map(document.getElementById("map"), mapOptions)
      })
      .catch(e => {
        // do something
      })
  }, [])
  return (
     <div ref={googleMapRef} className="box"></div>
  )
}

export default MapSection;




